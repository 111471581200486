import React from "react"
import styles from "./pay.module.scss"

const PartnersPay = () => (
  <div className={styles.pay}>
    <div className="container" >
      <div className={styles.wrap}>
        <div className={styles.top}>
          <h2 className={styles.title}>Сколько зарабатывают партнеры Параплан CRM?</h2>
          <p className={styles.text}>Сумма зависит от способа обработки заявки и вовлеченности нашей команды</p>
        </div >
        <ul className={styles.cards}>
          <li className={`${styles.card} ${styles.blue}`}>
            <p className={styles.subtitle}>Продажа и сопровождение <br /> осуществляются вашими силами.</p>
            <p className={styles.number}>25%</p>
            <p className={styles.text}>от стоимости покупки лицензии
              <br /> на любой период в течение двух лет</p>
          </li>
          <li className={`${styles.card} ${styles.green}`}>
            <p className={styles.subtitle}>Вы приводите клиента, а продажа <br />
              и сопровождение осуществляются нами.</p>
            <p className={styles.number}>15%</p>
            <p className={styles.text}>от стоимости покупки лицензии
              <br /> на любой период в течение двух лет</p>
          </li>
        </ul>
        <div className={styles.recommend}>
          Рекомендуйте Параплан CRM и увеличивайте свой доход!
        </div>
      </div>
    </div>
  </div>
)

export default PartnersPay
