import React from "react"
import styles from "./hero-partners-page.module.scss"
import BecomepartnerOverlayService from "../../../../../services/becomepartner-overlay-service"

const HeroPartnersPage = ({ name }) => (
  <div className={styles.hero}>

    <div className={`container ${styles.container}`}>
      <h1 className={styles.heading}>
        Начните зарабатывать с Параплан
      </h1>
      <p className={styles.subheading}>Привлекайте новых пользователей в Параплан <br className={"hide-mobile"} />
        и получайте за это деньги</p>
      <button type='button'
        className={styles.cta}
        onClick={() => BecomepartnerOverlayService.open()}>Стать партнером
      </button>
    </div>

  </div>
)

export default HeroPartnersPage
