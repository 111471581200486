import React from "react"
import styles from "./rec.module.scss"
import AboutComponent from "../../../../components/pages/main/about"

const PartnersRec = () => (
  <div className={styles.rec}>
    <div className="container">
      <div className={styles.wrap}>
        <div className={styles.top}>
          <h2 className={styles.title}>Рекомендуйте удобный сервис</h2>
          <p className={styles.text}>CRM-система Параплана создана с учетом специфики малого и среднего бизнеса</p>
        </div>
        <div  className={styles.recwrap}>
          <img
            alt={"Скриншот системы"}
            src={require("../../../../images/screenshot/partners/screen.png")}/>
          <div  className={styles.recdescr}>
            Параплан CRM содержит понятные и прозрачные алгоритмы работы с лидами, клиентами и сотрудниками.
            Специальные разделы и блоки помогают легко выстроить процесс учета посещаемости, оформления абонементов,
            контроля команды и ведения отчетности. Интеграции позволяют объединить несколько сервисов в одной системе.<br/><br/>
            <b>Не нужно продумывать сложные связки и формулы. В CRM все уже продумано за пользователя</b>.
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default PartnersRec
