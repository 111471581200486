import React from "react"
import HeroFeaturePage from "../components/pages/shared/hero/partners"
import SEO from "../components/seo"
import Layout from "../components/layout"
import ContactFormComponent from "../components/pages/shared/contact-form"
import HowItWork from "../components/pages/partners/how-it-works"
import Pay from "../components/pages/partners/pay"
import Banner from "../components/pages/partners/banner"
import Rec from "../components/pages/partners/recomendation"
import Steps from "../components/pages/partners/steps"
import Support from "../components/pages/partners/support"
import Breadcrumb from '../components/controls/breadcrumbs';

const FranchisePage = (props) => {
    const crumbs = [
        { name: 'Главная', url: '/'},
        { name: 'Партнерам', url: ''}
    ];
  const supportlist = [
    { icon:  require("../images/partners/support/partnership.svg"), descr: 'Моментальное подключение к партнерской программе'},
    { icon:  require("../images/partners/support/globe.svg"), descr: 'Возможность бартерных рекламных активностей'},
    { icon:  require("../images/partners/support/gift.svg"), descr: 'Бесплатный тестовый аккаунт для изучения CRM'},
    { icon:  require("../images/partners/support/thumb-up.svg"), descr: 'Отсутствие сертификации и длительного обучения'},
    { icon:  require("../images/partners/support/pay.svg"), descr: 'Доступ к инструкциям, презентациям, чек-листам'},
    { icon:  require("../images/partners/support/support.svg"), descr: 'Персональный менеджер поддержки партнеров'},
    { icon:  require("../images/partners/support/banknote.svg"), descr: 'Денежные выплаты в удобном формате'},
    { icon:  require("../images/partners/support/documentation.svg"), descr: 'Готовая отчетность по вашим заявкам и продажам'},
    { icon:  require("../images/partners/support/user-check.svg"), descr: 'Возможность работы с физ.лицами, юр.лицами, самозанятыми'},
    { icon:  require("../images/partners/support/percent.svg"), descr: 'Ежемесячные выплаты комиссии без дополнительных запросов'},
  ];
    return (
      <Layout headerColor={"#6D20D2"} page={"franchise"}>

          <SEO title="Параплан для партнёров | CRM-система учета для детских центров"
               description="Партнерская программа CRM системы Параплан. Подключайтесь к программе и зарабатывайте от 15 до 25 процентов от стоимости подписки каждого клиента."
               keywords={[`Параплан для партнёров`, `разработка CRM-системы`]} url={"https://paraplancrm.ru" + props.location.pathname}
          />

          <HeroFeaturePage
            name={"franchise"}
            title='Параплан для партнёров'
            description='Прозрачность и контроль внутренних процессов.'
            color='#03C4A4'
          />
          <Breadcrumb crumbs={ crumbs } arrowcolor={"purple"}/>
          <HowItWork />
          <Pay />
          <Banner />
          <Rec />
          <Steps />
          <Support supportlist={supportlist}/>
          <ContactFormComponent />
      </Layout>
    )
}

export default FranchisePage
