import React from "react"
import styles from "./steps.module.scss"
import BecomepartnerOverlayService from "../../../../services/becomepartner-overlay-service"

const PartnersSteps = () => (
  <div className={styles.steps}>
    <div className="container">
      <div className={styles.wrap}>
        <div className={styles.top}>
          <h2 className={styles.title}>Ваши шаги для подключения к партнерской программе</h2>
        </div>
        <div  className={styles.stepswrap}>
          <div  className={styles.step}>
            <div  className={styles.iconswrap}>
              <img
                alt={"Шаг 1"} className={styles.icon}
                src={require("../../../../images/partners/steps/buy.svg")}/>
              <img
                alt={"Стрелка"} className={styles.right}
                src={require("../../../../images/partners/steps/right.svg")}/>
              <img
                alt={"Стрелка"} className={styles.down}
                src={require("../../../../images/partners/steps/down.svg")}/>
            </div>
            <div  className={styles.textwrap}>
              <div  className={styles.digital}>1</div>
              <div  className={styles.txt}>
                Оставьте заявку на подключение к партнерской программе <a onClick={() => BecomepartnerOverlayService.open()}>тут</a>.
            </div>
            </div>
          </div>

          <div  className={styles.step}>
            <div  className={styles.iconswrap}>
              <img
                alt={"Шаг 2"} className={styles.icon}
                src={require("../../../../images/partners/steps/notification.svg")}/>
              <img
                alt={"Стрелка"} className={styles.right}
                src={require("../../../../images/partners/steps/right.svg")}/>
              <img
                alt={"Стрелка"} className={styles.down}
                src={require("../../../../images/partners/steps/down.svg")}/>
            </div>
            <div  className={styles.textwrap}>
              <div  className={styles.digital}>2</div>
              <div  className={styles.txt}>
                Получите индивидуальную реферальную ссылку.
              </div>
            </div>
          </div>

          <div  className={styles.step}>
            <div  className={styles.iconswrap}>
              <img
                alt={"Шаг 3"} className={styles.icon}
                src={require("../../../../images/partners/steps/edit-statement.svg")}/>
              <img
                alt={"Стрелка"} className={styles.right}
                src={require("../../../../images/partners/steps/right.svg")}/>
              <img
                alt={"Стрелка"} className={styles.down}
                src={require("../../../../images/partners/steps/down.svg")}/>
            </div>
            <div  className={styles.textwrap}>
              <div  className={styles.digital}>3</div>
              <div  className={styles.txt}>
                Заключите партнерский <br/>договор.
              </div>
            </div>
          </div>

          <div  className={styles.step}>
            <div  className={styles.iconswrap}>
              <img
                alt={"Шаг 4"} className={styles.icon}
                src={require("../../../../images/partners/steps/increase.svg")}/>
              <img
                alt={"Стрелка"} className={styles.right}
                src={require("../../../../images/partners/steps/right.svg")}/>
              <img
                alt={"Стрелка"} className={styles.down}
                src={require("../../../../images/partners/steps/down.svg")}/>
            </div>
            <div  className={styles.textwrap}>
              <div  className={styles.digital}>4</div>
              <div  className={styles.txt}>
                Отправляйте вашу реферальную ссылку заинтересованным клиентам или продавайте первую подписку на Параплан самостоятельно.
              </div>
            </div>
          </div>

          <div  className={styles.step}>
            <div  className={styles.iconswrap}>
              <img
                alt={"Шаг 5"} className={styles.icon}
                src={require("../../../../images/partners/steps/balance.svg")}/>
              <img
                alt={"Стрелка"}
                src={require("../../../../images/partners/steps/check.svg")}/>
            </div>
            <div  className={styles.textwrap}>
              <div  className={styles.digital}>5</div>
              <div  className={styles.txt}>
                Получайте агентскую комиссию в размере 15% или 25% от стоимости покупки каждой лицензии на любой период в течение двух лет.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default PartnersSteps
