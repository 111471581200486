import React from "react"
import styles from "./how-it-work.module.scss"

const PartnersHowItWork = () => (
  <div className={styles.work}>
    <div className="container">
      <div className={styles.wrap}>
        <h2 className={styles.title}>Кто может работать по партнерской программе?</h2>
        <ul className={styles.items}>
          <li className={`${styles.item} ${styles.purple}`}>
            <p className={styles.subtitle}>Вы занимаетесь внедрением различных <br /> CRM-cистем?</p>
            <p className={styles.text}>Внедрите систему Параплан своим клиентам
              и получите комиссию от нашей команды.</p>
          </li>
          <li className={`${styles.item} ${styles.green}`}>
            <p className={styles.subtitle}>Вы знаете, кому будет <br />
              полезен Параплан?</p>
            <p className={styles.text}>Получите уникальную ссылку и собирайте комиссию с каждой покупки.</p>
          </li>
          <li className={`${styles.item} ${styles.yellow}`}>
            <p className={styles.subtitle}>Вы предоставляете услуги для бизнесов из сфер образования, спорта, красоты или творчества?
            </p>
            <p className={styles.text}>Мы будем рады стать партнерами и предлагать ваши услуги нашим клиентам.
            </p>
          </li>
          <li className={`${styles.item} ${styles.orange}`}>
            <p className={styles.subtitle}>Вы хотите предложить индивидуальные <br /> условия?</p>
            <p className={styles.text}>Мы открыты для общения и готовы рассмотреть ваши предложения по встречному сотрудничеству.</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
)

export default PartnersHowItWork
